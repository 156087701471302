export interface FormItemBrokerType<T = unknown> {
  id?: string
  value?: T
  onChange?: (val?: T) => void
  children?: (val: Omit<FormItemBrokerType<T>, 'children'>) => JSX.Element
}

export const WithFormValue = (props: FormItemBrokerType<unknown>): JSX.Element => {
  const { id, value, onChange, children } = props
  if (!children) {
    return <></>
  }
  return children({ id, value, onChange })
}
