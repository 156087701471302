import React from 'react'

export const formGridInputStyle: React.CSSProperties = {
  backgroundColor: 'transparent ',
  border: 'none',
  borderBottom: '1px solid #d9d9d9',
  color: 'white',
}

export const formGridDarkInputStyle: React.CSSProperties = {
  backgroundColor: 'transparent ',
  border: 'none',
  borderBottom: '1px solid rgba(59, 45, 45, 0.5)',
}
