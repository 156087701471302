import { Typography } from 'antd'

const { Title } = Typography

type Props = {
  matches: boolean
  num: number
}

export default function Circle({ num, matches }: Props): JSX.Element {
  const size = matches ? 44 : 32

  return (
    <div
      style={{
        backgroundColor: '#1890FF',
        border: '1px solid #1890FF',
        borderRadius: '50px',
        paddingTop: '8px',
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '1rem',
        flexShrink: '0',
      }}
    >
      <Title level={3} style={{ color: '#fff', fontSize: size / 2.5 }}>
        {num}
      </Title>
    </div>
  )
}
