import moment from 'moment'
import { ShowNotification } from '../../../components/Notification'
import { AdditionalDriver } from '../types/customer'

export const toDriverForm = (driver: AdditionalDriver[]): Record<string, string | number> => {
  return driver.reduce((acc, driver, index) => {
    const driverForm = Object.entries(driver).reduce((acc, [mainKey, value]) => {
      if (mainKey === 'name') {
        acc[mainKey + '.x.' + (index + 1)] = value
        return acc
      }
      return Object.entries(value).reduce((acc, [key, value]) => {
        acc[mainKey + '.' + key + '.' + (index + 1)] = value
        return acc
      }, acc)
    }, {} as any)
    return { ...acc, ...driverForm }
  }, {})
}
export const toDriverApi = (
  nbreDrivers: number,
  driver: Record<string, string | number>,
): AdditionalDriver[] => {
  const array = Array.from({ length: nbreDrivers }).map(() => ({
    name: '',
    drivingLicense: {},
    identification: {},
  })) as AdditionalDriver[]
  const objEntries = Object.entries(driver) as any
  for (const [key, value] of objEntries) {
    const [mainKey, second, index] = key.split('.') as [
      keyof AdditionalDriver | 'name',
      keyof AdditionalDriver[keyof AdditionalDriver],
      string,
    ]
    const driverIndex = Number(index) - 1
    if (mainKey === 'name') array[driverIndex][mainKey] = value
    else
      (array as any)[driverIndex][mainKey][second] =
        second === 'expiryDate' ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value
  }
  return array as any
}

export const verifyDrivers = (
  nbreDrivers: number,
  driverForm: Record<string, number | string>,
): null | AdditionalDriver[] => {
  const validKeys = Object.keys(driverForm).filter(
    (key) => key.startsWith('drivingLicense.image') || key.startsWith('identification.image'),
  )
  const hasImage = validKeys.every((key) => !!driverForm[key])
  if (!hasImage) {
    ShowNotification.error('Please upload all images for additional drivers')
    return null
  }
  return toDriverApi(nbreDrivers, driverForm)
}
