import { DatePicker, Input } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import moment from 'moment'
import { useEffect } from 'react'
import NationalitySelect from '../../../../components/Select/nationality_select'
import { WithFormValue } from '../../../../components/WithFormValue'
import { formGridInputStyle } from '../../constants/form_grid_input_style'
import RenderForm from './render_form'

export type PassportDetails = {
  identityNumber: number
  expiryDate: string
  country: string
}

type Props = {
  initialData?: PassportDetails
  matches: boolean
  /** from is used to verify that all fields have been filled */
  form: FormInstance<unknown>
}

export default function PassportInformation({ initialData, form, matches }: Props): JSX.Element {
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [initialData])

  const PASSPORT_DB_KEY = 'cl_number'
  const EXPIRE_DATE_DB_KEY = 'cl_expiredate'
  const COUNTRY_DB_KEY = 'cl_country'
  const items = [
    {
      label: 'Passport no.',
      name: PASSPORT_DB_KEY,
      rules: [{ required: true, message: 'Please input your passport no!' }],
      children: (
        <Input
          style={formGridInputStyle}
          // onBlur={async () => await saveInformation(form, PASSPORT_DB_KEY)}
        />
      ),
    },
    {
      label: 'Expiry Date',
      name: EXPIRE_DATE_DB_KEY,
      rules: [{ required: true, message: 'Please input your expiration date!' }],
      children: (
        <WithFormValue>
          {({ value, onChange }: any) => {
            const toMomentValue = value ? moment(value) : undefined
            return (
              <DatePicker
                value={toMomentValue}
                format={'YYYY-MM-DD'}
                style={{ ...formGridInputStyle, width: '100%' }}
                onChange={onChange}
                // onBlur={async () => await saveInformation(form, EXPIRE_DATE_DB_KEY)}
              />
            )
          }}
        </WithFormValue>
      ),
    },
    {
      label: 'Country',
      name: COUNTRY_DB_KEY,
      children: (
        <NationalitySelect
          style={{ width: '100%' }}
          // onBlur={async () => await saveInformation(form, COUNTRY_DB_KEY)}
          placeholder="Select a country"
        />
      ),
    },
  ]

  return <RenderForm items={items} matches={matches} form={form} />
}
