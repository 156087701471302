import { SelectProps } from 'antd'
import { useState } from 'react'
import { getNationalityOptions } from '../../services/auth'
import BaseSelection, { BaseOption } from './base_select'

export default function NationalitySelect({ ...props }: SelectProps): JSX.Element {
  const [loading, setLoading] = useState(false)

  const getOptions = async (p: any) => {
    setLoading(true)
    const response = await getNationalityOptions(p.query).catch(() => null)
    setLoading(false)
    if (response)
      return response.map(
        (d) => ({ value: d.nationalityid, label: d.nationalityname } as BaseOption<number>),
      )
    return [] as BaseOption<number>[]
  }
  return (
    <BaseSelection
      {...props}
      loading={loading}
      preload
      keys={['label', 'value']}
      getResourceData={getOptions}
    />
  )
}
