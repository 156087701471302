import { Space } from 'antd'
import Form, { FormInstance, Rule } from 'antd/lib/form'

export type FormItem = {
  label: string
  name: string
  rules?: Rule[] | undefined
  children: JSX.Element | Element
  hidden?: boolean
}

type Props = {
  items: FormItem[]
  matches: boolean
  form: FormInstance<unknown>
  dark?: boolean
}

export default function RenderForm({ dark, items, matches, form }: Props): JSX.Element {
  return (
    <Form form={form} className="form-container" data-match={matches.toString()}>
      <div className="form-grid" data-match={matches.toString()}>
        {items.map((item) => {
          if (item.hidden) {
            return (
              <Form.Item hidden key={item.name} name={item.name} rules={item.rules}>
                {item.children}
              </Form.Item>
            )
          }
          return (
            <Space direction="vertical" key={item.name}>
              <label className={`form-label ${dark ? 'dark' : ''}`}>
                {item?.rules?.length ? <span style={{ color: 'red', marginRight: 5 }}>*</span> : ''}
                {item.label}
              </label>
              <Form.Item key={item.name} name={item.name} rules={item.rules}>
                {item.children}
              </Form.Item>
            </Space>
          )
        })}
      </div>
    </Form>
  )
}
