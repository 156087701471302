/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { Modal, Select, SelectProps } from 'antd'
import { useEffect, useState } from 'react'
import { useDebounce } from '../../utils/debounce'

type WithQuery<T> = T & { query: string | undefined }
export type BaseOption<T extends number | string = number> = { label: string; value: T }

interface BaseSelectProps<MinimalData, ParamsFilters> extends SelectProps<number> {
  filters?: ParamsFilters
  /**
   * label - primary key
   */
  keys: [keyof MinimalData, keyof MinimalData]
  getResourceData: (filters?: WithQuery<ParamsFilters | {}>) => Promise<MinimalData[]>
  preload?: boolean
  closeNoticeError?: boolean
  updateQuery?: (query: string) => void
  value?: number | null
  valueKey?: string
  fetchOne?: (id?: number) => Promise<MinimalData>
  formatValue?: (value: any) => string
}

function errorMessage(error: any) {
  return error?.response?.data?.message || error?.response?.data || error?.message || error
}

export default function BaseSelect<MinimalData extends Record<string, any>, ParamsFilters = {}>(
  props: BaseSelectProps<MinimalData, ParamsFilters>,
): JSX.Element {
  const { getResourceData, valueKey, filters: filtering, formatValue } = props || {}
  const [resourceList, setResourceList] = useState<MinimalData[]>([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const queryDebounced = useDebounce(query, 500)

  async function fetchData(propvalue?: undefined) {
    try {
      setLoading(true)
      const filters: WithQuery<ParamsFilters | Record<string, unknown>> = {
        query: queryDebounced,
        ...filtering,
      }
      if (propvalue && valueKey) {
        filters[valueKey as any] = propvalue
      }

      const resources = await getResourceData(filters)
      setResourceList(resources)
    } catch (error) {
      if (!props?.closeNoticeError) Modal.error({ content: errorMessage(error) })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (props.preload) {
      fetchData()
      return
    }
    if (queryDebounced.length > 2) fetchData()
  }, [queryDebounced, props.filters])

  useEffect(() => {
    if (props.value)
      formatValue
        ? fetchData(formatValue(props.value as any) as any)
        : fetchData(props.value as any)
  }, [props.value])

  return (
    <Select
      loading={loading}
      optionFilterProp={'label'}
      placeholder="Start typing to search"
      showSearch
      onSearch={setQuery}
      options={resourceList.map(
        (data) =>
          ({
            label: String(data[props.keys[0] as keyof MinimalData]),
            value: data[props.keys[1] as keyof MinimalData],
          } as BaseOption),
      )}
      {...props}
    />
  )
}
