import { DatePicker, Input } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import moment from 'moment'
import { useEffect } from 'react'
import NationalitySelect from '../../../../components/Select/nationality_select'
import { WithFormValue } from '../../../../components/WithFormValue'
import { formGridInputStyle } from '../../constants/form_grid_input_style'
import RenderForm from './render_form'

export type DrivingLicenseDetails = {
  identityNumber: number
  expiryDate: string
  country: string
}

type Props = {
  initialData?: DrivingLicenseDetails
  matches: boolean
  /** from is used to verify that all fields have been filled */
  form: FormInstance<unknown>
}

export default function DrivingLicenseInformation({
  initialData,
  form,
  matches,
}: Props): JSX.Element {
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [initialData])

  const DRIVING_LICENSE_DB_KEY = 'dl_number'
  const EXPIRE_DATE_DB_KEY = 'dl_expiredate'
  const COUNTRY_DB_KEY = 'dl_country'
  const items = [
    {
      label: 'Driving license details',
      name: DRIVING_LICENSE_DB_KEY,
      rules: [{ required: true, message: 'Please input your passport no!' }],
      children: (
        <Input
          style={formGridInputStyle}
          // onBlur={async () => await saveInformation(form, DRIVING_LICENSE_DB_KEY)}
        />
      ),
    },
    {
      label: 'Expiry Date',
      name: EXPIRE_DATE_DB_KEY,
      rules: [{ required: true, message: 'Please input your expiration date!' }],
      children: (
        <WithFormValue>
          {({ value, onChange }: any) => {
            const toMomentValue = value ? moment(value) : undefined
            return (
              <DatePicker
                style={{ ...formGridInputStyle, width: '100%' }}
                value={toMomentValue}
                format={'YYYY-MM-DD'}
                onChange={onChange}
                // onBlur={async () => await saveInformation(form, EXPIRE_DATE_DB_KEY)}
              />
            )
          }}
        </WithFormValue>
      ),
    },
    {
      label: 'Country',
      name: COUNTRY_DB_KEY,
      children: (
        <NationalitySelect
          style={{ width: '100%' }}
          // onBlur={async () => await saveInformation(form, COUNTRY_DB_KEY)}
        />
      ),
    },
  ]

  return <RenderForm items={items} matches={matches} form={form} />
}
