import { Image } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { VehiclePicture } from '../../../containers/Booking/types/reservation'

interface Iprops {
  img: VehiclePicture[]
  matches: boolean
}

const Card: React.FC<any> = ({ url, matches }: any) => {
  return (
    <div
      style={{
        flexShrink: 0,
        margin: matches ? '0 0 0 20px' : '0 0 0 10px',
      }}
    >
      <Image
        src={url}
        alt="vehicle_reservation"
        style={{
          width: matches ? 200 : 150,
          aspectRatio: '4/3',
          borderRadius: 10,
        }}
      />
    </div>
  )
}

const SlideCard: React.FC<Iprops> = ({ matches, img }) => {
  // const lengthContainer = matches ? (img.length - 2) * 200 - 100 : (img.length - 2) * 150
  const lengthTotal = matches ? img.length * 220 : img.length * 160

  const [startX, setStartX] = useState<number | null>(null)
  const [endX, setEndX] = useState<number | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  //find end position of slide card
  let endPos = containerRef.current ? lengthTotal - containerRef.current.offsetWidth : lengthTotal
  if (endPos < 0) endPos = 0

  //position of card container
  const [posX, setPosX] = useState<number>(0)

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX)
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startX === null) return

    setEndX(e.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (startX !== null && endX !== null) {
      const deltaX = endX - startX

      setPosX(posX - deltaX)
    }

    // Reset the start and end X values
    setStartX(null)
    setEndX(null)
  }

  useEffect(() => {
    // console.log(posX)
    if (posX <= 0) {
      setPosX(0)
    }

    if (posX > endPos) {
      setPosX(endPos)
    }
  }, [posX])

  return (
    <>
      <div
        ref={containerRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          width: matches ? '90%' : '100%',
          height: matches ? '225px' : '120px',
          // backgroundColor: 'red',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          // backgroundColor: 'pink',
        }}
      >
        <div
          style={{
            position: 'relative',
            right: posX,
            transition: '0.3s',
            // backgroundColor: 'blue',
            height: 200,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {img.map((e, idx) => {
            return <Card url={e.url} key={idx} matches={matches} />
          })}
        </div>
      </div>
    </>
  )
}

export default SlideCard
