import { Divider, Typography } from 'antd'

type Props = (
  | {
      divider?: undefined
      src: string
      alt: string
      matches: boolean
    }
  | {
      divider?: true
      src?: undefined
      alt?: undefined
      matches?: undefined
    }
) & { title: string }
const { Text } = Typography

/** Show the heading related to the uplaoding system */
export default function HeaderSection({ src, alt, title, matches, divider }: Props): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        margin: '1rem 0 1rem 0',
      }}
    >
      {divider && (
        <Divider style={{ color: ' white', borderColor: 'white' }} className="divider-inner-text">
          {title}
        </Divider>
      )}
      {!divider && <img src={src} alt={alt} />}
      {!divider && (
        <Text
          style={{
            fontSize: matches ? '24px' : '18px',
            color: '#ffffff',
            marginLeft: '1rem',
          }}
        >
          {title}
        </Text>
      )}
    </div>
  )
}
