import alipay from '../../../static/img/icon/payment/Alipay.svg'
import master from '../../../static/img/icon/payment/MasterCard.svg'
import visa from '../../../static/img/icon/payment/Visa.svg'

export interface IradioOption {
  value: number
  label: string
  imgUrl: any[]
}

const paymentOptions: IradioOption[] = [
  {
    value: 1,
    label: 'Credit / Debit cards',
    imgUrl: [visa, master],
  },
  {
    value: 2,
    label: 'Alipay',
    imgUrl: [alipay],
  },
]

export default paymentOptions
