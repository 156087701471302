import { Spin, Typography } from 'antd'

const { Text } = Typography

export default function WaitingData(): JSX.Element {
  return (
    <div style={{ display: 'flex', margin: '0 0 1rem 1rem' }}>
      <Spin />
      <div>
        <Text style={{ color: '#fff', margin: '0 0 0 1rem' }}>Loading Data</Text>
      </div>
    </div>
  )
}
