/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import config from '../../config'
import { CustomerDetails } from '../../containers/Booking/components/form/customer_information'
import { DrivingLicenseDetails } from '../../containers/Booking/components/form/driving_license_information'
import { PassportDetails } from '../../containers/Booking/components/form/passport_information'
import { AdditionalDriver } from '../../modules/booking/types/customer'
const { restfulService } = config

export const uploadImg = async (img: File, verifyToken: any, pathName: any) => {
  const fd = new FormData()
  fd.append('fileImage', img)
  fd.append('verifyToken', verifyToken)
  fd.append('cardNumber', '')
  fd.append('issueDate', '')
  fd.append('expirationDate', '')

  const customHeader = {
    'Content-Type': 'multipart/form-data',
  }

  return await axios.post(`${restfulService.URL}/customer-api/upload/file/${pathName}`, fd, {
    headers: customHeader,
  })
}

export const getReservationDataByToken = async (verifyToken: any, bookingToken: any) => {
  const reqBody = {
    verifyToken: verifyToken,
    bookingToken: bookingToken,
  }

  return await axios.post(`${restfulService.URL}/customer-api/booking/data`, reqBody)
}

export const saveUserInformation = async (
  data: Partial<
    CustomerDetails &
      PassportDetails &
      DrivingLicenseDetails & {
        additional_drivers: AdditionalDriver[]
      }
  >,
) => {
  const verifyToken = localStorage.getItem('verifyToken')
  const bookingToken = localStorage.getItem('bookingToken')
  const reqBody = {
    verifyToken: verifyToken,
    bookingToken: bookingToken,
  }

  return await axios.post(`${restfulService.URL}/customer-api/booking/user/data`, {
    ...data,
    reqBody,
  })
}

export const getDocStatusByToken = async (verifyToken: any) => {
  const reqBody = {
    verifyToken: verifyToken,
  }

  return await axios.post(`${restfulService.URL}/customer-api/document/status`, reqBody)
}

export const getOmiselink = async (posData: any) => {
  return await axios.post(`${restfulService.URL}/customer-api/payment`, posData)
}
