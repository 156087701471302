import { Col, Row, Typography } from 'antd'
import stepContent from '../../constants/step_content'
import Step from '../steps'
const { Text } = Typography
type Props = {
  matches: boolean
}

export default function StepSection({ matches }: Props): JSX.Element {
  return (
    <Row
      justify="center"
      style={{
        backgroundColor: '#003C92',
        borderRadius: matches ? '8px' : '35px',
        margin: '5px',
        paddingTop: matches ? '100px' : '36px',
        minHeight: '100%',
        overflow: 'hidden',
      }}
    >
      <Col span={22}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              fontSize: '3rem',
              fontWeight: matches ? 500 : 600,
              color: '#fff',
              ...(!matches && { textAlign: 'center', fontSize: '2rem' }),
            }}
          >
            Step to check-in
          </Text>
          <div
            style={{
              display: matches ? 'flex' : 'grid',
              gap: 4,
              alignItems: 'center',
              width: '100%',
              marginBottom: '1rem',
            }}
          >
            <Text
              style={{
                fontSize: '1.125rem',
                fontWeight: '500',
                ...(!matches && { textAlign: 'center' }),
                color: '#fff',
              }}
            >
              Quick step to get a car.
            </Text>
            <Text
              strong
              style={{
                color: '#38F95C',
                fontSize: '1.125rem',
                ...(!matches && { textAlign: 'center', fontSize: '1rem' }),
              }}
            >
              JUST FILL INFO AND GO
            </Text>
          </div>

          {stepContent.map((e) => (
            <Step key={e.num} num={e.num} matches={matches} message={e.message} />
          ))}
        </div>
      </Col>
    </Row>
  )
}
