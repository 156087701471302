const stepContent = [
  {
    num: 1,
    message: 'Enter your booking number to enter the check-in system.',
  },
  {
    num: 2,
    message: `Fill in your passport and driver's license details.`,
  },
  {
    num: 3,
    message: 'Submit information and getting QR code.',
  },
  {
    num: 4,
    message: 'Show QR code to staff',
  },
]

export default stepContent
