import { AdditionalDriver } from '../types/customer'

const emptyDriverData: AdditionalDriver = {
  name: '',
  identification: {
    type: 'passport',
    expiryDate: '',
    number: '',
    image: '',
  },
  drivingLicense: {
    expiryDate: '',
    image: '',
    number: '',
  },
}
export default emptyDriverData
