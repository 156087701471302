import { CarOutlined } from '@ant-design/icons'
import { IuploadItem } from '../types/uploadItem'
import styleIcon from './style_icon'

const uploadDriverItems: IuploadItem[] = [
  {
    name: `Driver's license`,
    path: 'car_license',
    icon: <CarOutlined style={styleIcon} />,
    msgVerify: 'Car Driver License',
    msgInprogress: 'Car Driver License',
    msgReject: 'Car Driver License',
    require: true,
  },
  // {
  //   name: `Driver's license 2 (Optional)`,
  //   path: 'car_license_2',
  //   icon: <CarOutlined style={styleIcon} />,
  //   msgVerify: 'Car Driver License',
  //   msgInprogress: 'Car Driver License',
  //   msgReject: 'Car Driver License',
  // },
  {
    name: `Selfie with driver's license`,
    path: `car_license_selfie`,
    icon: <CarOutlined style={styleIcon} />,
    msgVerify: 'Car Driver License',
    msgInprogress: 'Car Driver License',
    msgReject: 'Car Driver License',
    require: true,
  },
]

export default uploadDriverItems
