import { Col, Form, Row, Typography } from 'antd'
import React from 'react'
import { IuploadItem } from '../../../containers/Booking/types/uploadItem'
import UploadItem from './UploadItem'

interface Iprops {
  label: string
  uploadItems: IuploadItem[]
  matches: boolean
  activated: string
  status: string | undefined
}

const { Text } = Typography

const UploadImg: React.FC<Iprops> = ({ label, matches, activated, uploadItems, status }) => {
  const [form] = Form.useForm()

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        width: matches ? '85%' : '100%',
        backgroundColor: '#fff',
        padding: '1rem 0 1rem 0',
        margin: '1rem 0 1rem 0',
      }}
    >
      <Col xs={14} md={16}>
        {uploadItems.map((e, idx) => {
          return <UploadItem key={idx} item={e} activated={activated} status={status} />
        })}
      </Col>
    </Row>
  )
}

export default UploadImg
