import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Form, FormInstance, Popconfirm, Typography } from 'antd'
import { Fragment, useEffect, useMemo } from 'react'
import additionalDriversFormItems from '../../constants/additional_driver_form_items'
import { AdditionalDriver } from '../../types/customer'
import { toDriverForm } from '../../utils/addition_drivers'
import RenderForm from './render_form'

type Props = {
  form: FormInstance<any>
  drivers: AdditionalDriver[]
  readOnly?: boolean
  matches: boolean
  removeDriver: (index: number) => void
  addDriver: () => void
}

/** Handles recovering information for additional drivers */
export default function AdditionalDriversForm({
  form,
  removeDriver,
  addDriver,
  matches,
  drivers,
  readOnly = false,
}: Props): JSX.Element {
  const numberOfDrivers = drivers.length

  const formItems = useMemo(() => {
    const items = []
    for (let i = 0; i < numberOfDrivers; i++) {
      items.push(
        additionalDriversFormItems({ isDisabled: readOnly }).map((item) => {
          const name = item.name + '.' + (i + 1)
          return { ...item, name }
        }),
      )
    }

    return items
  }, [numberOfDrivers])

  useEffect(() => {
    form.setFieldsValue(toDriverForm(drivers))
  }, [numberOfDrivers])

  return (
    <Fragment>
      <Form
        form={form}
        style={{
          width: '85%',
          padding: '1rem',
          borderRadius: 8,
          display: 'grid',
          gap: 8,
          paddingTop: '2rem',
          backgroundColor: numberOfDrivers ? 'rgba(221, 227, 238, 1)' : 'transparent',
        }}
      >
        {!!formItems.length &&
          formItems.map((group, index) => {
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '1rem',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography.Title
                    className="text-primary m-0 p-0"
                    level={4}
                    style={{ color: 'rgb(0, 60, 146)', padding: 0, margin: 0 }}
                  >
                    {' '}
                    Additional Driver {index + 1}
                  </Typography.Title>
                  <Typography.Title
                    className="text-primary m-0 p-0"
                    level={4}
                    style={{ color: 'rgb(0, 60, 146)', padding: 0, margin: 0 }}
                  >
                    300 THB
                  </Typography.Title>
                </div>
                <div style={{ border: '1px solid black', padding: '1rem', borderRadius: 8 }}>
                  <RenderForm form={form} matches={matches} dark items={group} />

                  {/* <div style={{ display: 'grid', gap: 8 }}>
                    <UploaderDriver
                      required
                      readOnly={readOnly}
                      initSrc={drivers[index].drivingLicense.image}
                      onChange={(url) => {
                        form.setFieldsValue({
                          [`drivingLicense.image.${index + 1}`]: url,
                        })
                      }}
                      title="Driving License Image"
                    />
                    <UploaderDriver
                      required
                      readOnly={readOnly}
                      initSrc={drivers[index].identification.image}
                      onChange={(url) => {
                        form.setFieldsValue({
                          [`identification.image.${index + 1}`]: url,
                        })
                      }}
                      title="National ID / Passport Image"
                    />
                  </div> */}
                  <Popconfirm title="Remove current driver" onConfirm={() => removeDriver(index)}>
                    <Button
                      danger
                      onMouseEnter={({ currentTarget }) => {
                        currentTarget.style.backgroundColor = 'red'
                        currentTarget.style.color = 'white'
                      }}
                      onMouseLeave={({ currentTarget }) => {
                        currentTarget.style.backgroundColor = 'transparent'
                        currentTarget.style.color = 'red'
                      }}
                      style={{
                        marginTop: '0.5rem',
                        borderRadius: 8,
                        backgroundColor: 'transparent',
                      }}
                    >
                      Remove
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )
          })}
      </Form>
      <Button className="add-driver" disabled={numberOfDrivers > 1} onClick={() => addDriver()}>
        <div>
          {numberOfDrivers <= 1 && <PlusCircleOutlined size={28} />}
          <span style={{ color: numberOfDrivers > 1 ? 'black' : 'white' }}>
            {numberOfDrivers > 1 ? 'Limited to 2 additional drivers' : 'Add Additional Driver'}
          </span>
          {numberOfDrivers <= 1 && (
            <span style={{ color: 'white', fontWeight: 700, marginLeft: 'auto' }}>300 THB</span>
          )}
        </div>
      </Button>
    </Fragment>
  )
}
