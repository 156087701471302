import { Col, Form, Row, Typography } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { getBookingToken, getVerifyToken } from '../../services/preCheckIn'
import CheckInForm from './components/checkin_form'
import EnterReservationDetails from './components/enter_reservation_details'
import CheckInSection from './components/sections/check_in_section'
import StepSection from './components/sections/step_section'
import WaitingData from './components/waiting_data'
import { Isubmit } from './interface'

const { Text } = Typography

const PreCheckInModule: React.FC = () => {
  const [form] = Form.useForm()

  const [submitFail, setSubmitFail] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [resNo, setResNo] = useState<string | null>(null)

  //detect the screen size
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches)

  //landscape tablet break point
  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches))
  }, [])

  const onFinish = async (values: Isubmit) => {
    const { reservationId, lastName } = values

    setSubmitFail(false)
    setLoading(true)
    try {
      const verifyData = await getVerifyToken(reservationId, lastName)
      const { verifyToken } = verifyData.data
      const bookingData = await getBookingToken(verifyToken)
      const { bookingToken } = bookingData.data
      localStorage.setItem('verifyToken', verifyToken)
      localStorage.setItem('bookingToken', bookingToken)
      setResNo(reservationId)
    } catch (error: any) {
      setSubmitFail(true)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    setSubmitFail(true)
  }

  const handleInputChange = (e: any) => {
    // Remove any non-numeric characters
    e.target.value = e.target.value.replace(/\D/g, '')
  }

  if (resNo) {
    const url = `/qr/${resNo}`
    return <Redirect to={url} />
  }

  return (
    <div>
      <Row
        style={{
          backgroundColor: '#0048B3',
          minHeight: '100vh',
          paddingBottom: '10px',
        }}
      >
        <Col md={24} lg={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <CheckInSection isMobile={matches}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <EnterReservationDetails matches={matches} />
                <Row
                  style={{
                    marginTop: matches ? 0 : '8px',
                  }}
                >
                  <Col offset={2} span={18}>
                    {loading && <WaitingData />}

                    {submitFail && (
                      <Text
                        type="danger"
                        style={{
                          marginBottom: '1rem',
                        }}
                      >
                        Your information is not correct, please check your reservation ID
                      </Text>
                    )}
                    <CheckInForm {...{ form, matches, onFinishFailed, onFinish, loading }} />
                  </Col>
                </Row>
              </div>
            </CheckInSection>
          </div>
        </Col>

        <Col md={24} lg={12}>
          <StepSection matches={matches} />
        </Col>
      </Row>
    </div>
  )
}

export default PreCheckInModule
