import { Col, Row, Typography } from 'antd'

const { Text } = Typography
type Props = {
  matches: boolean
}

/** Handles the logic to enter reservation id and go to checkin information or qr code */
export default function EnterReservationDetails({ matches }: Props): JSX.Element {
  return (
    <Row
      style={{
        marginTop: matches ? '18px' : '26px',
        marginBottom: '28px',
      }}
    >
      <Col offset={2} span={20}>
        <Text
          style={{
            fontSize: '0.75rem',
            color: '#fff',
          }}
        >
          Enter your Reservation ID and last name to start. Check in at the respective website. To
          make it easy and quick to get your car please verify your information in this website and
          Show QR code at the counter
        </Text>
      </Col>
    </Row>
  )
}
