import { Input } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import { useEffect } from 'react'
import NationalitySelect from '../../../../components/Select/nationality_select'
import { formGridInputStyle } from '../../constants/form_grid_input_style'
import { CustomerDetails } from '../../types/customer'
import RenderForm, { FormItem } from './render_form'

type Props = {
  initialData?: CustomerDetails
  matches: boolean
  /** from is used to verify that all fields have been filled */
  form: FormInstance<unknown>
}

export default function CustomerInformation({ initialData, form, matches }: Props): JSX.Element {
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [initialData])

  const items: FormItem[] = [
    {
      label: 'Customer name',
      name: 'name',
      rules: [{ required: true, message: 'Please input your name!' }],
      children: (
        <Input
          className="form-grid-input "
          style={formGridInputStyle}
          // onBlur={async () => await saveInformation(form, 'name')}
        />
      ),
    },
    {
      label: 'E-mail',
      name: 'email',
      rules: [{ required: true, message: 'Please input your email!' }],
      children: (
        <Input
          style={formGridInputStyle}
          type="email"
          // onBlur={async () => await saveInformation(form, 'email')}
        />
      ),
    },
    {
      label: 'Phone number',
      name: 'mobile',
      rules: [{ required: true, message: 'Please input your phone number!' }],
      children: (
        <Input
          style={formGridInputStyle}
          inputMode="numeric"
          // onBlur={async () => await saveInformation(form, 'phone')}
        />
      ),
    },
    {
      label: 'Country',
      name: 'nationalityid',
      children: (
        <NationalitySelect
          style={{ width: '100%' }}
          // onBlur={async () => await saveInformation(form, 'nationalityid')}
        />
      ),
    },
  ]

  return <RenderForm items={items} matches={matches} form={form} />
}
