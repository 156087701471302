const convertTime = (inputDateString: string): string => {
  // Parse the input date string
  const parsedDate = new Date(inputDateString)

  // Define arrays for month names and days of the week
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const year = parsedDate.getFullYear()
  const month = parsedDate.getMonth() // Returns a zero-based index
  const day = parsedDate.getDate()
  const hours = parsedDate.getHours()
  const minutes = parsedDate.getMinutes()

  // Format the date in the desired format
  const formattedDate = `${day} ${monthNames[month]} ${year} ${hours}:${minutes}`

  return formattedDate
}

export default convertTime
