import { Button, Form, FormInstance, Input, Typography } from 'antd'

const { Text } = Typography
type Props = {
  form: FormInstance<unknown>
  matches: boolean
  loading: boolean
  onFinish: (values: any) => void
  onFinishFailed: (errorInfo: any) => void
}

export default function CheckInForm({
  form,
  onFinishFailed,
  onFinish,
  loading,
  matches,
}: Props): JSX.Element {
  const fontLable: React.CSSProperties = {
    fontSize: matches ? '14px' : '12px',
    fontWeight: '400px',
    color: '#fff',
  }
  return (
    <Form
      name="basic"
      wrapperCol={{ span: 20 }}
      form={form}
      style={{ display: 'grid', gap: 5 }}
      // initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Text
        style={{
          ...fontLable,
          fontSize: '1rem',
          ...(!matches && { marginInline: 'auto' }),
        }}
      >
        Last name
      </Text>
      <Form.Item
        wrapperCol={{ span: 18 }}
        // label="Reservation ID"
        name="lastName"
        rules={[{ required: true, message: 'Last name is required' }]}
      >
        {' '}
        <Input placeholder="Last name..." />
      </Form.Item>
      <Text
        style={{
          ...fontLable,
          fontSize: '1rem',
          ...(!matches && { marginInline: 'auto' }),
        }}
      >
        Enter Reservation ID
      </Text>
      <Form.Item
        wrapperCol={{ span: 18 }}
        // label="Reservation ID"
        name="reservationId"
        rules={[{ required: true, message: 'Reservation No is required' }]}
      >
        <div style={{ display: 'grid', gap: 20 }}>
          <Input placeholder="xxxxxx" />
          <Button
            type="primary"
            loading={loading}
            style={{
              // paddingBlock: 10,

              width: 140,
              ...(!matches && { marginInline: 'auto' }),
              borderRadius: matches ? '8px' : '35px',
            }}
          >
            Start Check-in
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}
