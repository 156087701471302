/* eslint-disable @typescript-eslint/ban-types */
import { PropsWithChildren } from 'react'

export default function MainLayout({ children }: PropsWithChildren<{}>): JSX.Element {
  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        backgroundColor: '#0048B3',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  )
}
