import { DatePicker, Input, Radio } from 'antd'
import moment from 'moment'
import { WithFormValue } from '../../../components/WithFormValue'
import { FormItem } from '../components/form/render_form'
import { formGridDarkInputStyle } from './form_grid_input_style'
const dateFormat = 'YYYY-MM-DD'

const additionalDriversFormItems = (params: { isDisabled?: boolean }): FormItem[] => {
  const disabled = params.isDisabled || false
  return [
    {
      name: 'name.x',
      label: 'Full Name',
      children: <Input style={formGridDarkInputStyle} disabled={disabled} />,
      rules: [{ required: true, message: 'Please input your full name!' }],
    },
    {
      name: 'drivingLicense.number',
      label: 'Driving License Number',
      children: <Input style={formGridDarkInputStyle} disabled={disabled} />,
      rules: [{ required: true, message: 'Please input your driving license number!' }],
    },
    {
      name: 'drivingLicense.expiryDate',
      label: 'Driving License Expiry Date',
      children: (
        <WithFormValue>
          {({ value, onChange }: any) => {
            const toMomentValue = value ? moment(value) : undefined
            return (
              <DatePicker
                style={{ ...formGridDarkInputStyle, width: '100%' }}
                disabled={disabled}
                value={toMomentValue}
                format={dateFormat}
                onChange={onChange}
              />
            )
          }}
        </WithFormValue>
      ),
      rules: [{ required: true, message: 'Please input your driving license expiry date!' }],
    },
    {
      hidden: true,
      name: 'drivingLicense.image',
      children: <Input style={formGridDarkInputStyle} disabled={disabled} hidden />,
      label: 'Driving License Image',
      rules: [], // cannot be used with ts-uplod so hidden
    },
    {
      name: 'identification.type',
      label: 'Identification Type',
      children: (
        <WithFormValue>
          {({ value, onChange }: any) => {
            const isPassportSelected = value === 'passport'
            const isNationalCardSelected = value === 'national_card'
            return (
              <Radio.Group
                disabled={disabled}
                style={{ width: '100%' }}
                value={value}
                onChange={(e) => onChange(e.target.value)}
              >
                <Radio.Button value="passport">
                  <span style={{ color: isPassportSelected ? 'white' : 'black' }}>PASSPORT</span>
                </Radio.Button>
                <Radio.Button value="national_card">
                  {' '}
                  <span style={{ color: isNationalCardSelected ? 'white' : 'black' }}>
                    THAI ID CARD
                  </span>
                </Radio.Button>
              </Radio.Group>
            )
          }}
        </WithFormValue>
      ),
      rules: [{ required: true, message: 'Please input your identification type!' }],
    },
    {
      name: 'identification.number',
      label: 'National ID / Passport Number',
      children: <Input style={formGridDarkInputStyle} disabled={disabled} />,
      rules: [{ required: true, message: 'Please input your National ID / Passport number!' }],
    },
    {
      name: 'identification.expiryDate',
      children: (
        <WithFormValue>
          {({ value, onChange }: any) => {
            const toMomentValue = value ? moment(value) : undefined
            return (
              <DatePicker
                style={{ ...formGridDarkInputStyle, width: '100%' }}
                disabled={disabled}
                value={toMomentValue}
                format={dateFormat}
                onChange={onChange}
              />
            )
          }}
        </WithFormValue>
      ),
      label: 'National ID / Passport Expiry Date',
      rules: [
        { required: true, message: 'Please input your  National ID / Passport expiry date!' },
      ],
    },
    {
      hidden: true,
      name: 'identification.image',
      children: <Input style={formGridDarkInputStyle} hidden disabled={disabled} />,
      label: 'National ID / Passport Image',
      rules: [],
    },
  ]
}

export default additionalDriversFormItems
