import { Alert, Button, Radio, RadioChangeEvent, Space, message } from 'antd'

import React, { useEffect, useState } from 'react'
import UploadImg from '../../components/Booking/UploadImg'
import {
  getDocStatusByToken,
  getOmiselink,
  getReservationDataByToken,
  saveUserInformation,
} from '../../services/booking'
import { IresData } from './types/reservation'
import { IuserData } from './types/user'

import { QrcodeOutlined } from '@ant-design/icons'

import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import RadioOption from '../../components/Booking/RadioOption'
import LoadingPage from '../../components/LoadingPage'
import { ShowNotification } from '../../components/Notification'
import driverCardLogo from '../../static/img/icon/booking/Driver.svg'
import passportLogo from '../../static/img/icon/booking/Passport.svg'
import CustomerVerify from './components/customer_verify'
import AdditionalDriversForm from './components/form/addition_driver'
import CustomerInformation from './components/form/customer_information'
import DrivingLicenseInformation from './components/form/driving_license_information'
import PassportInformation from './components/form/passport_information'
import HeaderSection from './components/header_section'
import MainLayout from './components/main_layout'
import ReservationInformation from './components/reservation_information'
import emptyDriverData from './constants/empty_additional_driver'
import omisePaymentConfig from './constants/omise_payment_config'
import paymentOptions from './constants/payment_options'
import uploadDriverItems from './constants/upload_driver_items'
import uploadPassportItems from './constants/upload_passport_items'
import './index.css'
import { AdditionalDriver } from './types/customer'
import { IdocStatus } from './types/docstatus'
import { toDriverApi } from './utils/addition_drivers'

const BookingModule: React.FC = () => {
  const { resNo } = useParams<{ resNo: string }>()

  const [customerForm] = useForm()
  const [passportForm] = useForm()
  const [drivingLicenseForm] = useForm()
  const [additionalDriversForm] = useForm()

  const [resData, setResData] = useState<IresData | undefined>(undefined)
  const [userData, setUserData] = useState<IuserData | undefined>(undefined)

  const [nationalDocStatus, setNationalDocStatus] = useState<IdocStatus | null>(null)
  const [licenseDocStatus, setLicenseDocStatus] = useState<IdocStatus | null>(null)

  const [additionalDrivers, setAdditionalDrivers] = useState<AdditionalDriver[]>([])

  const [radioV, setRadioV] = useState(1)
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false)
  const [chargeSuccess, setChargeSuccess] = useState<boolean | undefined>(undefined)

  const verifyToken = localStorage.getItem('verifyToken')
  const bookingToken = localStorage.getItem('bookingToken')

  const handleChangeRadio = (e: RadioChangeEvent) => {
    setRadioV(e.target.value)
  }

  const verifyForms = async (): Promise<null | Record<string, any>> => {
    const promises = [
      customerForm.validateFields(),
      passportForm.validateFields(),
      drivingLicenseForm.validateFields(),
    ]
    return await Promise.all(promises)
      .catch((error) => {
        message.error(`Message Error: ${error}`)
        return null
      })
      .then((res) => {
        return res ? res.reduce((acc, cur) => ({ ...acc, ...cur }), {}) : null
      })
  }

  const listenPayment = async () => {
    try {
      const response = await getReservationDataByToken(verifyToken, bookingToken)
      const resInfo = response.data.data[0].reservationData
      const charge_last = resInfo.chargeData[0].chargestate

      if (charge_last) {
        if (charge_last.includes('CHARGED')) {
          setChargeSuccess(true)
          setResData(resInfo)
        }
      }
    } catch (error: any) {
      message.error(`Message Error: ${error}`)
    }
  }

  const handleConfirm = async () => {
    try {
      const body = await verifyForms()
      if (!body) return ShowNotification.error('Please fill in all required fields')

      body.cl_expiredate = moment(new Date(body.cl_expiredate)).format('YYYY-MM-DD')
      body.dl_expiredate = moment(new Date(body.dl_expiredate)).format('YYYY-MM-DD')

      // set drivers data
      if (additionalDrivers.length) {
        const res = await additionalDriversForm.validateFields().catch(() => null)
        if (res === null) return ShowNotification.error('Please fill in all required fields')
        const drivers = toDriverApi(
          additionalDrivers.length,
          additionalDriversForm.getFieldsValue(),
        )
        body.additional_drivers = drivers
      }

      // save user information
      await saveUserInformation(body)

      setLoadingPayment(true)
      let response
      if (radioV == 1) {
        response = await getOmiselink({ ...omisePaymentConfig, channel: '' })
      } else {
        response = await getOmiselink({ ...omisePaymentConfig, channel: 'ALIPAY' })
      }
      setChargeSuccess(false)

      const { link_url }: any = response.data.charge
      window.location.replace(link_url)
    } catch (error) {
      message.error(`Message Error: ${error}`)
    } finally {
      setLoadingPayment(false)
    }
  }

  //detect the screen size
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches)

  useEffect(() => {
    if (chargeSuccess === false) {
      const paymentInterval = setInterval(listenPayment, 3000)
      return () => {
        clearInterval(paymentInterval)
      }
    }
  }, [chargeSuccess])

  //landscape tablet break point
  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches))
    getReservationDataByToken(verifyToken, bookingToken)
      .then((response) => {
        const result = response.data.data[0]
        setUserData(result.userData)
        setResData(result.reservationData)
      })
      .catch((error: any) => {
        message.error(`Message Error: Not Found Reservation Data !`)
        setTimeout(() => {
          window.location.replace('/')
        }, 3000)
      })

    getDocStatusByToken(verifyToken)
      .then((response) => {
        // console.log(response.data.data.docs)
        setNationalDocStatus(response.data.data.docs[0].status)
        setLicenseDocStatus(response.data.data.docs[3].status)
      })
      .catch((error: any) => {
        message.error(`Message Error: Not Found Document Status !`)
      })
  }, [])

  const isDepositPaid = resData
    ? resData?.chargeData.length > 0 &&
      resData.chargeData[0]?.chargestate.includes('CHARGED') &&
      resData.depositprice_b > 0
    : false

  if (!userData || !resData)
    return (
      <MainLayout>
        <LoadingPage />
      </MainLayout>
    )

  return (
    <MainLayout>
      <CustomerVerify
        activatedUser={userData.activated}
        charges={resData.chargeData}
        matches={matches}
        nationalDocStatus={nationalDocStatus?.approveStatus ?? ''}
        licenseDocStatus={licenseDocStatus?.approveStatus ?? ''}
      />

      <div
        style={{
          width: matches ? '75%' : '100%',
          backgroundColor: '#003C92',
          marginTop: matches ? '1rem' : 0,
          borderRadius: matches ? '10px' : 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '1rem',
          // marginBottom: resData.depositprice_b == 0 ? '2rem' : '0',
        }}
      >
        <ReservationInformation user={userData} reservation={resData} />
        <Link to={{ pathname: `/qr/${resNo}` }}>
          <Button size="large" icon={<QrcodeOutlined />} style={{ borderRadius: 5 }}>
            My QR
          </Button>
        </Link>
        {/* CUSTOMER */}
        <HeaderSection title="Customer Information" divider />
        <CustomerInformation form={customerForm} matches={matches} />
        {/* PASSPORT */}
        <HeaderSection title="Passport" src={passportLogo} alt="passport_logo" matches={matches} />
        <PassportInformation form={passportForm} matches={matches} />
        <UploadImg
          label="Passport"
          uploadItems={uploadPassportItems}
          matches={matches}
          activated={userData.activated}
          status={nationalDocStatus?.approveStatus}
          // setDocStatus={setNationalDocStatus}
        />
        {/* DRIVING LICENSE */}

        <HeaderSection
          alt="Driving licence logo"
          src={driverCardLogo}
          matches={matches}
          title="Driving Information"
        />
        <DrivingLicenseInformation form={drivingLicenseForm} matches={matches} />
        {/* DRIVING LICENSE */}
        <HeaderSection
          src={driverCardLogo}
          alt="driver_logo"
          title="Driver license"
          matches={matches}
        />
        <UploadImg
          label="Driver’s license"
          uploadItems={uploadDriverItems}
          matches={matches}
          activated={userData.activated}
          status={licenseDocStatus?.approveStatus}
          // setDocStatus={setLicenseDocStatus}
        />
        <AdditionalDriversForm
          matches={matches}
          form={additionalDriversForm}
          drivers={additionalDrivers}
          addDriver={() => {
            if (additionalDrivers.length > 1) {
              message.error('Maximum 3 drivers allowed')
              return
            }
            setAdditionalDrivers((prev) => [...prev, emptyDriverData])
          }}
          removeDriver={(index: number) => {
            setAdditionalDrivers((prev) => prev.filter((_, i) => i !== index))
          }}
        />
      </div>

      {isDepositPaid && (
        <Alert
          message="Already Paid the Deposit"
          type="success"
          showIcon
          style={{ margin: '2rem 0 2rem 0' }}
        />
      )}

      {!(resData.chargeData.length > 0 && resData.chargeData[0]?.chargestate.includes('CHARGED')) &&
        resData.depositprice_b > 0 && (
          <>
            <div
              style={{
                width: matches ? '60%' : '100%',
                height: '180px',
                marginTop: matches ? '2rem' : 0,
                backgroundColor: '#fff',
                borderBottomLeftRadius: matches ? 0 : '40px',
                borderBottomRightRadius: matches ? 0 : '40px',
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: 'var(--character-primary-85, rgba(0, 0, 0, 0.85))',
                  height: 68,
                  borderBottom: '1px solid #60ACFF',
                  backgroundColor: '#DDF0FF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
                  <div>Deposit payment</div>
                  <div>{`${resData.depositprice_b.toLocaleString()}B`}</div>
                </div>
              </div>

              <div
                style={{
                  margin: '2rem 0 0 2rem',
                  color: 'var(--character-title-85, rgba(0, 0, 0, 0.85))',
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                <Radio.Group value={radioV} onChange={handleChangeRadio}>
                  <Space direction="vertical">
                    {paymentOptions.map((e, idx) => (
                      <RadioOption key={idx} {...e} />
                    ))}
                  </Space>
                </Radio.Group>
              </div>
            </div>
            {additionalDrivers.length && (
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: 'var(--character-primary-85, rgba(0, 0, 0, 0.85))',
                  height: 68,
                  borderBottom: '1px solid #60ACFF',
                  backgroundColor: '#DDF0FF',
                  display: 'flex',
                  width: matches ? '60%' : '100%',
                  marginTop: '1rem',
                  paddingInline: matches ? '7rem' : '2rem',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>
                  {additionalDrivers.length} Additional Driver
                  {additionalDrivers.length > 1 ? 's' : ''} -{' '}
                  <span style={{ color: 'grey' }}>Pay at the counter</span>
                </span>
                <span style={{ color: '#1A1A1A', fontSize: 20 }}>
                  <strong>{300 * additionalDrivers.length} THB</strong>
                </span>
              </div>
            )}

            <div
              style={{
                width: '100%',
                marginTop: matches ? '2rem' : '3rem',
                backgroundColor: '#ffffff',
                height: 80,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: matches ? '70%' : '90%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: 13,
                      fontWeight: 300,
                    }}
                  >
                    Deposit payment
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >{`${resData.depositprice_b.toLocaleString()} B`}</div>
                </div>

                <Button
                  type="primary"
                  loading={loadingPayment}
                  style={{ width: matches ? 300 : 150 }}
                  onClick={handleConfirm}
                >
                  Confirm & Pay
                </Button>
              </div>
            </div>
          </>
        )}
    </MainLayout>
  )
}

export default BookingModule
