import { Typography } from 'antd'
import { IdocStatus } from '../types/docstatus'
import { IresData } from '../types/reservation'
import { IuserData } from '../types/user'

const { Text } = Typography

type Props = {
  matches: boolean
  activatedUser: IuserData['activated']
  charges: IresData['chargeData']
  licenseDocStatus: IdocStatus['approveStatus']
  nationalDocStatus: IdocStatus['approveStatus']
}

export default function CustomerVerify({
  matches,
  activatedUser,
  charges,
  licenseDocStatus,
  nationalDocStatus,
}: Props): JSX.Element {
  const isCompleted =
    activatedUser === 'TRUE' &&
    charges[0]?.chargestate.includes('CHARGED') &&
    nationalDocStatus.includes('APPROVED') &&
    licenseDocStatus.includes('APPROVED')
  return (
    <div
      style={{
        marginTop: matches ? '1rem' : 0,
        width: matches ? '95%' : '100%',
        height: '43px',
        backgroundColor: '#1D2530',
        display: 'flex',
        alignItems: 'center',
        justifyContent: matches ? 'start' : 'space-between',
      }}
    >
      <div style={{ marginLeft: matches ? '2rem' : '1rem', marginRight: matches ? '2rem' : 0 }}>
        <Text style={{ fontSize: '11px', color: '#fff' }}>Customer verify</Text>
      </div>

      <div style={{ marginRight: matches ? 0 : '1rem' }}>
        <Text style={{ fontSize: '11px', color: '#fff' }}>
          Status :{' '}
          <Text
            style={{
              fontSize: '13px',
              color: isCompleted ? '#2ECC71' : '#EA8181',
              fontWeight: '600',
            }}
          >
            {isCompleted ? 'Complete' : 'Pending'}
          </Text>
        </Text>
      </div>
    </div>
  )
}
