import axios from 'axios'
import config from '../../config'
const { restfulService } = config

export const getVerifyToken = async (resNo: string, lastName: string) => {
  const url = `${restfulService.URL}/customer-api/airport-transfer/verify-login`
  const reqBody = {
    reservationNo: resNo,
    lastName,
  }

  return await axios.post(url, reqBody)
}

export const getBookingToken = async (verifyToken: string | undefined) => {
  const url = `${restfulService.URL}/customer-api/booking/token`
  const reqBody = {
    verifyToken: verifyToken,
  }

  return await axios.post(url, reqBody)
}
