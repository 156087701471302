import { IdcardOutlined } from '@ant-design/icons'
import { IuploadItem } from '../types/uploadItem'
import styleIcon from './style_icon'

const uploadPassportItems: IuploadItem[] = [
  {
    name: 'Passport (user page)',
    path: 'national_id',
    icon: <IdcardOutlined style={styleIcon} />,
    msgVerify: 'National ID card or Passport',
    msgInprogress: 'National ID card or Passport',
    msgReject: 'National ID card or Passport',
    require: true,
  },
  {
    name: 'Selfie with passport (user page)',
    path: 'national_id_selfie',
    icon: <IdcardOutlined style={styleIcon} />,
    msgVerify: 'National ID card or Passport',
    msgInprogress: 'National ID card or Passport',
    msgReject: 'National ID card or Passport',
    require: true,
  },
]

export default uploadPassportItems
