import { Col, Row, Typography } from 'antd'
import { Fragment, useEffect, useState } from 'react'
import SlideCard from '../../../components/Booking/SlideCard'
import convertTime from '../../../utils/convert_time'
import { IresData } from '../types/reservation'
import { IuserData } from '../types/user'
const { Text } = Typography

type Props = {
  reservation: IresData
  user: IuserData
}

export default function ReservationInformation({
  reservation: resData,
  user: userData,
}: Props): JSX.Element {
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches)

  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches))
  }, [])

  if (!resData || !userData) return <></>
  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '50px',
          width: matches ? '80%' : '100%',
          borderBottom: matches ? '0.5px solid #fff' : 0,
        }}
      >
        <Text style={{ color: '#fff', fontSize: '18px', marginLeft: '1rem' }}>
          {`${resData.vehicleData.vehiclebrand}  ${resData.vehicleData.vehiclemodel}`}
        </Text>
      </div>
      <SlideCard matches={matches} img={resData.vehiclePicture} />
      <div
        style={{
          width: matches ? '90%' : '95%',
          backgroundColor: 'rgba(29, 37, 48, 0.2)',
          margin: matches ? '0 0 1rem 0' : '1rem 0 1rem 0',
          padding: '1rem 2rem 1rem 2rem',
        }}
      >
        <Row
          style={{
            fontSize: 14,
            color: '#fff',
          }}
        >
          <Col xs={10} md={6} lg={4}>
            Reservation No
          </Col>

          <Col xs={10} md={5} lg={5}>
            {`: ${resData?.reservationno}`}
          </Col>

          <Col xs={10} md={3} lg={2}>
            Name
          </Col>

          <Col xs={10} md={5} lg={4}>
            {`: ${userData.name} ${userData.lastname}`}
          </Col>
        </Row>

        <Row
          justify="space-around"
          style={{
            color: '#fff',
            fontSize: 14,
            width: '100%',
            marginTop: '1rem',
            backgroundColor: 'rgba(0, 55, 134, 1)',
            zIndex: 5,
          }}
        >
          <Col xs={10} md={5}>
            <div>Start time</div>
            <div>{convertTime(resData.initstarttime)}</div>
          </Col>
          <Col xs={10} md={5} style={{ marginBottom: matches ? 0 : '1rem' }}>
            <div>Pick up location</div>
            <div>SUV BKK Airport</div>
          </Col>

          {matches && (
            <Col md={2}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <path
                    d="M18.62 13.42C18.4346 13.6051 18.2875 13.8248 18.1872 14.0668C18.0868 14.3087 18.0352 14.5681 18.0352 14.83C18.0352 15.092 18.0868 15.3513 18.1872 15.5933C18.2875 15.8352 18.4346 16.055 18.62 16.24L26.38 24L18.62 31.76C18.2461 32.134 18.036 32.6412 18.036 33.17C18.036 33.6989 18.2461 34.2061 18.62 34.58C18.994 34.954 19.5012 35.1641 20.03 35.1641C20.5589 35.1641 21.0661 34.954 21.44 34.58L30.62 25.4C30.8055 25.215 30.9525 24.9952 31.0529 24.7533C31.1533 24.5113 31.2049 24.252 31.2049 23.99C31.2049 23.7281 31.1533 23.4687 31.0529 23.2268C30.9525 22.9848 30.8055 22.7651 30.62 22.58L21.44 13.4C20.68 12.64 19.4 12.64 18.62 13.42Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Col>
          )}

          <Col xs={10} md={5}>
            <div>End time</div>
            <div>{convertTime(resData.initstoptime)}</div>
          </Col>

          <Col xs={10} md={4}>
            <div>Return location</div>
            <div>SUV BKK Airport</div>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}
