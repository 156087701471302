import { Typography } from 'antd'
import Circle from './circle_annotation'

const { Text } = Typography

type Props = {
  matches: boolean
  num: number
  message: string
}

export default function Step({ num, matches, message }: Props): JSX.Element {
  const circleSize = matches ? 44 : 32
  return (
    <div
      style={{
        color: '#fff',
        display: 'flex',
        width: '80%',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex' }}>
        <Circle num={num} matches={matches} />
        <Text style={{ fontSize: matches ? '24px' : '16px', color: '#fff' }}>{message}</Text>
      </div>

      {num !== 4 && (
        <div
          style={{
            marginBlock: '0.5rem',
            width: 'fit-content',
            height: matches ? '76px' : '57px',
            marginLeft: circleSize / 2 - 2.5, // half circle size and half border width
            border: '2px solid #58AFFF',
            marginBottom: '-0.3rem',
            ...(!matches && { marginTop: '-0.3rem' }),
          }}
        ></div>
      )}
    </div>
  )
}
